function setFaviconForTheme() {
    let link = document.querySelector("link[rel*='shortcut icon']") || document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        link.href = '/static/logos/wordmark_micro_light.svg';
    } else {
        link.href = '/static/logos/wordmark_micro_dark.svg';
    }
    
    document.getElementsByTagName('head')[0].appendChild(link);
}

setFaviconForTheme();
